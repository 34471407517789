


import React, { useState, useEffect,useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ReactModal from 'react-modal';
import '../../styles/style.scss';
import moment from 'moment';
import { Checkbox, Divider,AutoComplete  } from 'antd';
import {ReactComponent as ArrowDown} from '../../../assets/images/arrow-down-long.svg'
import {ReactComponent as ArrowUp} from '../../../assets/images/arrow-up-long.svg'
import {ReactComponent as ArrowDownUp} from '../../../assets/images/arrow-down-arrow-up.svg'
import {ReactComponent as CircleArrow} from '../../../assets/images/caret-right.svg';
import {ReactComponent as CircleArrowDown} from '../../../assets/images/caret-down.svg'
import {ReactComponent as EditIcon} from '../../../assets/images/pen-to-square.svg'
import {ReactComponent as DeleteIcon} from '../../../assets/images/trash-can-xmark.svg'
import {ReactComponent as AddIcon} from '../../../assets/images/square-plus.svg'
import { AgGridReact,AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useHistory } from 'react-router-dom';
import LoaderSpinner from '../../../assets/images/loader.svg'
import CreateGroup from './CreateGroup';
import EditGroup from './EditGroup';
import {getDepartmentList,getEmployeeDetail,removeDepartment,removeSubDepartment,removeUser} from '../../actionMethods/actionMethods'
import {ReactComponent as GroupIcon} from '../../../assets/images/hexagon-plus.svg'
import Avatar from '../../../assets/images/avatar.png'
import WarningModal from '../../../components/WarningModal';
import SucessModal from '../../../components/SucessModal';
import ErrorModal from '../../../components/ErrorModal';

function GroupList(props){
    
    const defaultCheckedList = ['Apple', 'Orange'];
    const plainOptions = ['Apple', 'Pear', 'Orange'];

    const [checkedList, setCheckedList] = React.useState(defaultCheckedList);
    const [indeterminate, setIndeterminate] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);    
    const [activeAccord,SetActiveAccord] =useState([]);
    const [WidthContainer,setWidthContainer]=useState('');
    const [loader,SetLoader]=useState(true);
    const elementRef = useRef(null);   
    const [GroupListData,SetGroupListData] = useState([])
    const [DefaultGroupListData,SetDefaultGroupListData] = useState([])
    const [listEmp,SetListEmp]=useState([])
    const [listEmpDefault,SetListEmpDefault]=useState([])
    const [parentGroup,SetParentGroup]=useState([])
    const [availableTag,SetAvailTag]=useState(props.availableTag?props.availableTag:[])   
    const [modalDelete,SetModalDelete] =useState(false); 
    const [title,SetTitle]=useState('')
    const [description,SetDescription]=useState('')
    const [ID,SetID] =useState('')
    const [type,SetType] =useState('')
    const [modalSucess,SetModalSucess] =useState(false);
    const [modalError,SetModalError] = useState(false);
    const [inc,SetInc] =useState(0)



    let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
    let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
    let org_id = userDetails.org_id?userDetails.org_id:""
    let sub_org_id = userDetails.sub_org_id?userDetails.sub_org_id:""
    const [permissionDepartment,SetPermissionDepartment]=useState([]);
    const [permissionManpower,SetPermissionManpower] =useState([]);
    //let org_id = 23
    useEffect(()=>{
        if(props.permission!=null && props.permission){
            let dept= JSON.parse(props.permission[0].group);
            SetPermissionDepartment(dept);
            let Manp = JSON.parse(props.permission[0].employee);
            SetPermissionManpower(Manp)
        }

    },[props.permission])

    useEffect(() => {
        setWidthContainer(elementRef.current.getBoundingClientRect().width);
    }, [elementRef]);

    useEffect(()=>{

        let data = [...DefaultGroupListData]
 
        let arr= []
        let allemp = [];
        let parentUniq= []
        for(let i=0;i<data.length;i++){
            //child exist
           if(data[i].name.toString().toLowerCase().includes(props.searchUser.toString().toLowerCase())){
            
            arr.push(data[i]);
           }
           else if(data[i].child){
          
                let emp = [];
                let arr2=[];
                let subCat= [];
               for(let y=0;y<data[i].child.length;y++){
                   

                let element2 = data[i].child[y];
                 
                
                if(element2.sub_department.toString().toLowerCase().trim().includes(props.searchUser.toString().toLowerCase().trim())){
                   

                    subCat.push(element2)
                }else{

                     let emp = element2.data.length > 0 ?element2.data.filter((item) => {
                        
                            return (
                                item.emp_name.toString().toLowerCase().trim().includes(props.searchUser.toLowerCase().trim()) || 
                            item.tag_serial.toString().toLowerCase().trim().includes(props.searchUser.toLowerCase().trim()) ||
                            item.worker_id.toString().toLowerCase().trim().includes(props.searchUser.toLowerCase().trim()) 
                        
                        )     
                        
                        
                        
                        
                    }):"";
                   
                    if(emp.length>0){
                   
                        subCat.push({sub_department:element2.sub_department,sub_dept_id:element2.sub_dept_id,data:emp})
                    }
                }

               } 
               if(subCat.length>0){

                   arr.push({name:data[i].name,dept_id:data[i].dept_id,child:subCat})   
                }                

           }else{
            // let emp = data[i].data.filter((item) => {
            //     return (item.emp_name.toString().toLowerCase().trim().includes(props.searchUser.toLowerCase().trim()) || 
         
            //     item.tag_serial.toString().toLowerCase().trim().includes(props.searchUser.toLowerCase().trim()) ) 
                
            //    // item.emp_name.toString().toLowerCase().includes(props.searchUser.toLowerCase());
            // })

            let emp = data[i].data.filter((item) => {
                
                     return (item.emp_name.toString().toLowerCase().trim().includes(props.searchUser.toLowerCase().trim()) || 
                    
                     item.tag_serial.toString().toLowerCase().trim().includes(props.searchUser.toLowerCase().trim()) )     

                 
             })
           
            
            if(emp.length>0){
           
                arr.push({name:data[i].name,dept_id:data[i].dept_id,data:emp,count:emp.length})
            }
           }
            
            

        }
        console.log("arr",arr)
        SetGroupListData(arr);
   
    },[props.searchUser])

    useEffect(() => {
        let requestBody={};
        requestBody.session=session
        requestBody.org_id=org_id
    
        requestBody.flag='employee'
        requestBody.sub_org_id=sub_org_id
        getEmployeeDetail(requestBody).then(res=>{

            let arr = [];
        
            if(res.status==200){
                let data= res.data
                let arr=[]
                let allemp=[];
                let parentUniq=[]
                Object.keys(data).forEach(key=>{

                    let child=data[key];
                    let arr2=[];
                                 
                   
                    if(Object.keys(child['sub_department']).length > 0 ){
                        let empcount=[]
                        Object.keys(child['sub_department']).forEach(key2=>{
                            let emp=[]; 

                            for(let i=0 ;i<child['sub_department'][key2]['employee'].length;i++){
                                let element = child['sub_department'][key2]['employee'][i];
                               
                                for(let j=0 ;j<element.length;j++ ){
                                    element[j].department=key
                                    
                                   allemp.push(element[j])
                                   empcount.push(element)
                                }
                                emp.push(element);
                                

                            }
                            arr2.push({sub_department:key2,sub_dept_id:child['sub_department'][key2]['sub_dept_id'],data:emp&&emp[0]?emp[0]:emp})
                           
                        })
                        
                        arr.push({name:key,dept_id:child['dept_id'],child:arr2,count:empcount.length})
                      
                    }else{
                        let emp=[];
                        if(child['employee']){
                            for(let i=0 ;i<child['employee'].length;i++){
                                let element = child['employee'][i].employee;
                                element.department=key
                                element.dept_id=child['dept_id']
                                emp.push(element);
                                allemp.push(element)
                            }
                        }
                        arr.push({name:key,dept_id:child['dept_id'],data:emp,count:emp.length})
                    }

                    parentUniq.push({name:key,id:child['dept_id']});                    
                })
                SetParentGroup(parentUniq);
                SetDefaultGroupListData(arr);
                SetGroupListData(arr);
                SetListEmp(allemp);
                SetListEmpDefault(allemp)
                SetLoader(false)
            }
        })

    }, [props.inc]);

    function DisplayNameProfile(params){
        if(params.value){

        }else{

        }
            

        var op =document.createElement('div');

        var eGui = document.createElement('div');
      
         eGui.innerHTML= '<div class="element-icon ant-table-cell textCap">'+
                            '<span class="profileTable"><img src='+Avatar+' />'+params.value.toLowerCase()+'</span>'+''+
                            
                           '</div>';
      
         return eGui;

    }

    function  AGData(name,data,type){

       let  filter= [];

       
        filter = listEmpDefault.filter(item => item.department.toString().includes(name))         
       

        if(data && data.length==undefined){
            filter = [data];
        }else if(data){
            filter = data;
        }
        let passing='array';
        if (typeof Array.isArray === 'undefined') {
            passing='string';
        }else{
            passing='array';
        }

      let arr=  <div className="ag-theme-alpine" style={{height:(60 + ( 40* filter.length + 1 ) ), width: '100%'}}>
        <AgGridReact
          rowData={filter}
          defaultColDef={{
               sortable: true,
               resizable: true,
               width: type? ( WidthContainer - 120)/4:( WidthContainer - 80)/4,
               height:40,
          }}
        >
            <AgGridColumn 
               field="emp_name"
               headerName={"Employee Name"}    
               //valueFormatter={(params)=><><img src={Avatar} />{params.value}</>  }
               cellRenderer= {(params)=>DisplayNameProfile(params)}
           ></AgGridColumn>
            
            <AgGridColumn 
               field="worker_id"
               headerName={"Worker ID"}
               
           ></AgGridColumn>
           <AgGridColumn 
               field="tag_serial"
               headerName={"Tag Serial"}
           ></AgGridColumn>
           <AgGridColumn 
               field="emp_id"
               headerName={"Action"}
               cellRenderer= {(params)=>EdiButtonRender(params)}
           ></AgGridColumn>
      </AgGridReact>     
      </div>

      return arr;
    }
    
function SucessMessage(){
    SetModalSucess(true);
}
function ErrorMessage(){
    SetModalError(true);
}

function  handleDeleteConfirm(){

        let requestBody={};
        requestBody.org_id= org_id
        requestBody.session= session
        if(type=='User'){
            requestBody.employee_id=ID
            console.log("requestbody",requestBody)
            removeUser(requestBody).then(res=>{
                if(res.status==200){
                    SetModalDelete(false);
                    SetTitle(<h4>User is deleted</h4>);        
                    SetDescription('');
                    SucessMessage()

                    props.Reload()
                }else{
                    SetTitle(<h4>User is not deleted</h4>);        
                    SetDescription('Have Some Error');        
                    ErrorMessage()
                }
            })
        }else if(type=="Department"){
            requestBody.department_id=ID
            requestBody.sub_org_id=sub_org_id
            removeDepartment(requestBody).then(res=>{
                if(res.status==200){
                    SetModalDelete(false);
                    SetTitle(<h4>Department is  deleted</h4>);        
                    SetDescription('');
                    SucessMessage()
                    props.Reload()
                }else{
                    SetTitle(<h4>{res.message?res.message:"Department is not deleted"}</h4>);        
                    SetDescription('Have Some Error');        
                    ErrorMessage()
                }
            })
            
        }else if(type=='Sub Department'){
            requestBody.sub_department_id=ID
            removeSubDepartment(requestBody).then(res=>{
                if(res.status==200){
                    SetModalDelete(false);
                    SetTitle(<h4>Sub Department is  deleted</h4>);        
                    SetDescription('');
                    SucessMessage()
                    props.Reload()
                }else{
                    SetTitle(<h4>{res.message?res.message:"Sub Department is not deleted"}</h4>);        
                    SetDescription('Have Some Error');        
                    ErrorMessage()
                }
            })
           
        }
    }
    function handleCloseModalWarning(){
        SetModalDelete(false);
        //props.Reload()
    }
    function ShowGroupCardList(data){


        let arr=[]
        if(data.length > 0){
    
            for(let i=0;i<data.length;i++){

            arr.push( 
            <Row className={activeAccord.indexOf(i+1)!==-1 || props.searchUser.length > 0?'contentGroup active':'contentGroup'} key={'department'+data[i].name.toLowerCase()}>
            <div className='group-header'> 
             <Col lg={4} className="align-middle">
                 {/* <span className='align-with-title' style={{width:"50px"}}><Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} /></span> */}
               {listEmp.filter(item => item.department.toString().includes(data[i].name)).length > 0 || data[i].child
               ? <span className='accordion-icon' onClick={()=>AccordionHandler(i+1,'primary')} >
                   {activeAccord.indexOf(i+1)!==-1 || props.searchUser.length > 0 ?<CircleArrowDown/>:<CircleArrow />}     
                 </span>:''}

                 

                 <span className='align-title-left'>
                 <h4 className='textCap'>{data[i].name.toLowerCase()}</h4>
                     {/* <div className='sub-stats'><small>Active Sub Department : <strong>{data[i].child?data[i].child.length:0}</strong> Inactive Sub Department <strong>0</strong></small></div> */}
                 </span>
             </Col>
             <Col lg={3} className="align-middle" style={{textAlign:"center"}}>{listEmp.filter(item => item.department.toString().includes(data[i].name)).length}</Col>
             <Col lg={5} className="align-middle userManagementLast" style={{minWidth:"310px"}}>
                    {permissionDepartment && permissionDepartment.Create=='True'?
                        <button className='btn btn-smaller btn-icon' onClick={()=>props.modalOpenSubGroup(data[i].name,data[i].dept_id )} disabled><GroupIcon className='hexagonal-plus'/><span>Add Sub Department</span></button>:
                        <button className='btn btn-smaller btn-icon disabled hover-message' disabled><GroupIcon className='hexagonal-plus'/><span>Add Sub Department</span><span className='messageTag'>Access Restricted</span></button> 
                    }
                    /{permissionManpower && permissionManpower.Create=='True'?<button className='btn btn-smaller btn-icon' onClick={()=>props.modalOpenAddEmp(data[i].dept_id,data[i].name)}><AddIcon/><span>Add Employee</span></button>:<button className='btn btn-smaller btn-icon disabled hover-message'><AddIcon/><span>Add Employee</span><span className='messageTag'>Access Restricted</span></button>}
                    / {permissionDepartment && permissionDepartment.edit=='True'?<button className='btn btn-smaller btn-icon'onClick={()=>props.modalOpenGroupEdit(data[i].dept_id,data[i].name,parentGroup)}><EditIcon/><span>Edit</span></button>:<button className='btn btn-smaller btn-icon hover-message disabled' disabled><EditIcon/><span>Edit</span><span className='messageTag'>Access Restricted</span></button>}
                    /{permissionDepartment && permissionDepartment.remove=='True'?<button className='btn btn-smaller btn-icon' onClick={()=>{SetType('Department');SetID(data[i].dept_id);SetTitle(<><strong>{data[i].name}</strong> Department</>);SetModalDelete(true) }} ><DeleteIcon/><span>Delete</span></button>:<button className='btn btn-smaller btn-icon hover-message disabled btn-delete' disabled ><DeleteIcon/><span>Delete</span><span className='messageTag'>Access Restricted</span></button>}</Col>
             </div>
             
             {data[i].child?data[i].child.map(element2=>{
                 return(
                    <Row className={activeAccord.indexOf(element2.sub_department)!==-1 || props.searchUser.length > 0? 'contentGroup contentGroupSubCat active':'contentGroup contentGroupSubCat'} key={'SubDepart'+element2.sub_department}>      
                          <div className='group-header group-header-subCat'> 
                 {/* <Col lg={4} className="align-middle">
                     <span className='align-with-title' style={{width:"50px"}}></span>
                     {element2.data.length > 0 ?<span className='accordion-icon' onClick={()=>AccordionHandler(element2.sub_department)} >
                       {activeAccord.indexOf(element2.sub_department)!==-1  || props.searchUser.length > 0?<CircleArrowDown/>:<CircleArrow />}     
                     </span>:''}
                     <span className='align-title-left'>
                     <h4 className="truncate-text ">{element2.sub_department}</h4>
                    </span>
                 </Col> */}
                  <Col lg={4} className="align-middle">
      <div className="text-container" style={{ maxWidth: "300px", overflowX: "hidden", overflowY: "hidden",whiteSpace:"nowrap",textOverflow:"ellipsis" }}>
        <span className='align-with-title' style={{ width: "50px" }}></span>
        {element2.data.length > 0 && permissionManpower.View == "True"  ? (
          <span className='accordion-icon' onClick={() => AccordionHandler(element2.sub_department)}>
            {activeAccord.indexOf(element2.sub_department) !== -1 || props.searchUser.length > 0 ? <CircleArrowDown /> : <CircleArrow />}
          </span>
        ) : ''}
        <span className='align-title-left'>
          <h4 style={{ whiteSpace: "nowrap" }}>{element2.sub_department}</h4>
        </span>
      </div>
    </Col>
                 <Col lg={3} className="align-middle" style={{textAlign:"center"}}>{element2.data.length > 0 && element2.data[0].length > 0?element2.data[0].length:element2.data.length } </Col>
                 <Col lg={5} className="align-middle userManagementLast" style={{minWidth:"310px"}}>
                 {permissionManpower && permissionManpower.Create=='True'?props.availableTag.length>0?<button className='btn btn-smaller btn-icon' onClick={()=>props.modalOpenAddEmp(data[i].dept_id,data[i].name,element2.sub_dept_id)}><AddIcon/><span>Add Employee</span></button>:<button className='btn btn-smaller btn-icon disabled hover-message'><AddIcon/><span>Add Employee</span><span className='messageTag'>{props.availableTag.length} Personal Tag Available</span></button>:<button className='btn btn-smaller btn-icon disabled hover-message'><AddIcon/><span>Add Employee</span><span className='messageTag'>Access Restricted</span></button> }
                 / {permissionDepartment && permissionDepartment.edit=='True'?<button className='btn btn-smaller btn-icon'onClick={()=>props.modalSubGroupEditOpen(element2.sub_dept_id,element2.sub_department, data[i].dept_id,parentGroup)}><EditIcon/><span>Edit Sub Department</span></button>:<button className='btn btn-smaller btn-icon hover-message disabled'><EditIcon/><span>Edit Sub Department</span><span className='messageTag'>Access Restricted</span></button>}
                 /{permissionDepartment && permissionDepartment.remove=='True'?<button className='btn btn-smaller btn-icon' onClick={()=>{SetType('Sub Department');SetID(element2.sub_dept_id);SetTitle(<><strong>{element2.sub_department}</strong> Sub Department</>);SetModalDelete(true) }} ><DeleteIcon/><span>Delete</span></button>:<button className='btn btn-smaller btn-icon hover-message disabled btn-delete hover-message' ><DeleteIcon/><span>Delete</span><span className='messageTag'>Access Restricted</span></button>}</Col>
                 </div>
                 <div  className={activeAccord.indexOf(element2.sub_department)!==-1 || props.searchUser.length > 0?'group-content group-sub-content active':'group-content group-sub-content'}>
                    {AGData('',element2.data?element2.data?element2.data:element2.data:[],'sub_depet') }
                </div>    
                 </Row>)

             }):data[i].data.length > 0 ?<div ref={elementRef} className={activeAccord.indexOf(i+1)!==-1|| props.searchUser.length > 0?'group-content active':'group-content'}>
             {AGData(data[i].name,data[i].data) }
             </div>:""}



             

         </Row>



            )
            }
        }else{
            arr.push( 
                <Row className='contentGroup nofound'>
                    <div className='group-header'>
                        <Col className='align-middle'>
                            <h6 className='listNOFound' style={{marginBottom:"0px"}}>No Data found</h6>
                        </Col>
                    </div>
                </Row>
                ) 
        }

        return arr;

    }

    const onChange = list => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
      };
    
      const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? plainOptions : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
      };

    const [sort,SetSort]=useState('desc');
    const[sortKey,SetSortKey] =useState('');  


    useEffect(()=>{
        let arr= [...DefaultGroupListData]
       

        if(sortKey=='group'){
                
                arr = arr.sort((a, b) => {
                    a = a.name.toUpperCase()
                    b = b.name.toUpperCase()
                    return sort === 'desc' ? (a == b ? 0 : b > a ? 1 : -1) : (a == b ? 0 : a > b ? 1 : -1)
                })
        }
        if(sortKey=='tag_count'){
            arr = arr.sort(function (x, y) {
                return sort === 'desc' ?y.count - x.count:x.count-y.count;
            });
   

        }
        SetGroupListData(arr)

    },[sort,sortKey])


    function ChangeSorting(key){
        SetSort( sort=='desc'?'asc':'desc');
        SetSortKey(key);
    }
    function AccordionHandler(val,type='secondary'){
        let ActivList = [...activeAccord];
        let index = ActivList.indexOf(val)
        if(index !== -1){  
            ActivList.splice(index, 1);
            SetActiveAccord(ActivList);
        } 
        else if(type=='primary'){
            SetActiveAccord([val]);
         }    
        else {  
            ActivList.push(val);
            SetActiveAccord(ActivList);
        }


        
        
    }

    function HeaderEmpSubTab(){
        return (
            <Row className='subHeaderEmptTable'>
                <Col lg={3}>Employee Name <span className='filter-icon'><ArrowDownUp/></span></Col>
                <Col lg={3}>Role <span className='filter-icon'><ArrowDownUp/></span></Col>
                <Col lg={3}>Tag Serial <span className='filter-icon'><ArrowDownUp/></span></Col>
                <Col lg={3}>Action</Col>
            </Row>
        )
    }

    function editHandlerAlert(data){

        props.modalOpenEditEmployee(data);
    }

    function deleteHandlerAler(data){
        SetType('User');
        SetID(data.emp_id);
        console.log("data",data);
        SetTitle(<><strong>{data.emp_name}</strong>  User</>);
        SetModalDelete(true); 

    }
    function EdiButtonRender(params){
        

        var op =document.createElement('div');

        var eGui = document.createElement('div');
      
        eGui.addEventListener('click', event => {
          if(event.target.getAttribute("data-action")=='edit'){
            editHandlerAlert(params.data)
          }else if(event.target.getAttribute("data-action")=='delete'){
            deleteHandlerAler(params.data);
          }
        });
      
    //   {"chaitanya added ${permissionManpower && permissionManpower.edit !== 'True' ? 'disabled' : ''} in below buttons "}
        
            eGui.innerHTML=`<div class="element-icon ant-table-cell">
                                <button class="btn btn-edit btn-smaller btn-icon ${permissionManpower && permissionManpower.edit=='True'?'acessEnable':'hover-message disabled'}" data-action="edit" data-el="${params.value}" ${permissionManpower && permissionManpower.edit !== 'True' ? 'disabled' : ''}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M432 320c-8.836 0-16 7.164-16 16V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h112C184.8 96 192 88.84 192 80S184.8 64 176 64H63.1C28.65 64 0 92.65 0 128v320c0 35.35 28.65 64 63.1 64h319.1c35.35 0 63.1-28.65 63.1-64L448 336C448 327.2 440.8 320 432 320zM497.9 42.19l-28.13-28.14c-9.373-9.373-21.66-14.06-33.94-14.06s-24.57 4.688-33.94 14.06L162.4 253.6C153.5 262.5 147.4 273.9 144.9 286.3l-16.66 83.35c-1.516 7.584 4.378 14.36 11.72 14.36c.7852 0 1.586-.0764 2.399-.2385l83.35-16.67c12.39-2.479 23.77-8.566 32.7-17.5l239.5-239.5C516.7 91.33 516.7 60.94 497.9 42.19zM235.8 326.1c-4.48 4.48-10.13 7.506-16.35 8.748l-53.93 10.79L176.3 292.6c1.244-6.219 4.27-11.88 8.754-16.36l178.3-178.3l50.76 50.76L235.8 326.1zM475.3 87.45l-38.62 38.62l-50.76-50.76l38.62-38.62c4.076-4.076 8.838-4.686 11.31-4.686s7.236 .6094 11.31 4.686l28.13 28.14C479.4 68.9 480 73.66 480 76.14C480 78.61 479.4 83.37 475.3 87.45z"></path></svg> Edit <span class='messageTag'>Access Restricted</span></button>
                                <button class="btn btn-edit btn-smaller btn-icon ${permissionManpower && permissionManpower.remove=='True'?'acessEnable':'hover-message disabled'}" data-action="delete" data-el="${params.value}"${permissionManpower && permissionManpower.remove !== 'True' ? 'disabled' : ''}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 128C391.2 128 384 135.2 384 144v288c0 26.47-21.53 48-48 48h-224C85.53 480 64 458.5 64 432v-288C64 135.2 56.84 128 48 128S32 135.2 32 144v288C32 476.1 67.89 512 112 512h224c44.11 0 80-35.89 80-80v-288C416 135.2 408.8 128 400 128zM432 64h-96l-33.63-44.75C293.4 7.125 279.1 0 264 0h-80C168.9 0 154.6 7.125 145.6 19.25L112 64h-96C7.201 64 0 71.2 0 80C0 88.8 7.201 96 16 96h416C440.8 96 448 88.8 448 80C448 71.2 440.8 64 432 64zM152 64l19.25-25.62C174.3 34.38 179 32 184 32h80c5 0 9.75 2.375 12.75 6.375L296 64H152zM148.7 363.3c6.242 6.246 16.37 6.254 22.62 0L224 310.6l52.69 52.69c6.242 6.246 16.37 6.254 22.62 0c6.25-6.25 6.25-16.38 0-22.62L246.6 288l52.69-52.69c6.25-6.25 6.25-16.38 0-22.62s-16.38-6.25-22.62 0L224 265.4L171.3 212.7c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L201.4 288l-52.69 52.69C142.4 346.9 142.4 357.1 148.7 363.3z"></path></svg> Delete <span class='messageTag'>Access Restricted</span></button>
                            </div>`;
                


      
         return eGui;



    }

    return(

        <div className='roleList' ref={elementRef}>
                        <div className='br-seperator-row'>   
        <Row className='headerGroup' >
            
            <Col lg={4} className="align-middle">
                {/* <span className='align-with-title' style={{width:"50px"}}><Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} /></span> */}
                <span className="filter-parent" onClick={()=>ChangeSorting('group')}>Group
                <span className={sortKey=='group'?'filter-icon active':'filter-icon'} >{sortKey=='group'?sort=='desc'?<ArrowUp/>:<ArrowDown/>:<ArrowDownUp/>}</span></span>            
            </Col>
            
            <Col lg={3} className="align-middle" style={{textAlign:"center"}}>
            <span className="filter-parent" onClick={()=>ChangeSorting('tag_count')} >Employee Count
                <span className={sortKey=='tag_count'?'filter-icon active':'filter-icon'}>{sortKey=='tag_count'?sort=='desc'?<ArrowUp/>:<ArrowDown/>:<ArrowDownUp/>}</span></span>
            </Col>
            
            <Col lg={5} className="align-middle userManagementLast" style={{minWidth:"310px"}}>Action</Col>
        </Row>    
        {loader?<div className='loaderContainer'><img src={LoaderSpinner}/></div>:ShowGroupCardList(GroupListData)} 
        <WarningModal modalWarning={modalDelete} handleDeleteConfirm={handleDeleteConfirm} handleCloseModalWarning={handleCloseModalWarning} title={title} />
            <SucessModal modalSucess={modalSucess} handleCloseModalSucess={()=>SetModalSucess(false)} title={title} description={description} />
			<ErrorModal modalError={modalError} handleCloseModalError={()=>SetModalError(false)}   title={title} description={description}  />
        </div>

        </div>
        
    )
    

}
export default GroupList;