import React, { useState, useCallback,useReducer,useEffect,useRef,useLayoutEffect } from 'react'
import { Container, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Button } from "antd";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom";

import { CommonHeading } from "../../common/commonHeading";
import DashboardLanguage from "../../components/dashboardLanguage";
import CommonDatePicker from "../../common/commonDatePicker";
import dropdownIcon from "../../assets/images/down-arrow.png";
// import mediumRiskIcon from "../../assets/traceplusImages/medium_risk_icon.svg";
import spinnerLoader from '../../assets/images/loader.svg'
// import LoaderSpinner from  '../../assets/images/loader.svg'

import moment from "moment";
import { getTranslatedText } from "../../common/utilities";


import LiveTime from "../../components/LiveTime";
import Viewer from '../../geomapmanagement/components/Viewer' 
import '../style/style.css';
import { getTagStatus,getLocationStatus,getGatewayStatus, getGatewayHistory, getLocationHistory } from '../actionMethods/actionMethods';
import { getCategoryList } from '../../sitemanagement/actionMethods/actionMethods'

import {
  append,
  compose,
  isEmpty,
  map,
  reject,
  intersperse,
  evolve,
  max,
  mapObjIndexed,values, filter
} from 'ramda'
import { getLocationCordinate } from '../../geomapmanagement/actionMethods/actionMethods'
import ProductiveIcon from '../../assets/images/locationProductive.png'
import NonProductiveIcon from '../../assets/images/locationNonProductive.png'
import { AgGridColumn,AgGridReact } from 'ag-grid-react';
import Highcharts from "highcharts";
import ReactModal from 'react-modal';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import { Collapse } from 'antd';
import Scrollbars from 'react-custom-scrollbars';
import CMGManualWarehouse from '../../assets/cmg_manual.json'
import CMGBulkyWarehouse from '../../assets/cmg_bulky.json'
import CMGManualWarehouseGateway from '../../assets/cmg_manual_gateway.json'
import CMGBulkyWarehouseGateway from '../../assets/cmg_blky_gateway.json'


HighchartsHeatmap(Highcharts);
const riskLevelColor = {
  low: "#04e06e",
  medium: "#ffd700",
  high: "#ffa500",
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "90%",
    maxHeight:650
  },
};


let heatmapChart;
function DeviceMonitor(props) {
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
      const { Panel } = Collapse;
  const INITIAL_MODE = '3d'  
  const [mode, setMode] = useState(INITIAL_MODE)
  const [selectedDate, updateSelectedDate] = useState(date);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");

  const [PriData, updatePRIData] = useState("");
  const [prevPriData, updatePrevPriData] = useState("");

  const [selectedTab, updatedSelectedTab] = useState("employees");

  let userDetails = localStorage.getItem('userLoginDetailsTraceAdmin')!=null && localStorage.getItem('userLoginDetailsTraceAdmin')!='loggedIn'?JSON.parse(localStorage.getItem('userLoginDetailsTraceAdmin')):[];
  let session = userDetails.session!=null?userDetails.session:'ezrsrdfghawtsetrrAZSXCxfa';
  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : "";
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 6;
  let lastReqId = null
  const noElevationIn2D = useCallback(value => (mode === '3d' ? value : 0), [
    mode
  ])
  const autoElevation = map(
    evolve({ position: { elevation: noElevationIn2D } })
  )
    const [heightMap,SetHightMap]= useState(0);
  const [width, setWidth] = useState(0);
    
  const [space, setSpace] = useState()
  const [spaceID,SetSpaceID]=useState('');
  const [locationCordinate,SetLocationCordinate]= useState([]);
  const [isloading,Setloading] =useState(true);
  const onReady = useCallback(space => setSpace(space), [])
  let GeoWidth= userDetails && parseInt(userDetails.org_id) == 31?1.3:4;
  let GeoElevation= userDetails && parseInt(userDetails.org_id) == 31?3:5;
  const [loaderLocation,SetLoaderLcoation]= useState(true);
  // const [gateway,SetGateway] = useState(generateGeateway(40,"Gateway"));
  const [gateway,SetGateway] = useState([]);
  const [gatewaySearch,SetGatewaySearch]= useState('');
  const [gatewayAll,SetGatewayAll] = useState([]);
  
  const [location,SetLocation] = useState([]);
  const [locationSearch,SetLocationSearch]= useState('');
  const [locationAll,SetLocationAll] = useState([]);
  
  const [personal,SetPersonal] = useState([]);
  const [personalSearch,SetPersonalSearch]= useState('');
  const [personalAll,SetPersonalAll] = useState([]);

  const [showModal,SetShowModal] = useState(false);
  const elementRef =useRef(null);
  const [heatmapData,SetHeatMapData]=useState([])
  const [loadAPI,SetLoadAPI] = useState(true);
  const [locationList,SetLocationList] =useState([])
  const [timezoneDetail,SetTimeZoneDetail] = useState("")
  // const color= ["#D7F4E7","#A2D9BB","#84DBA6","#A1E5B9","#8FE0AA","#7DDB9A","#6BD68B","#59D17B","#47CC6C","green","green"]
  const color= ['#ff0000','#ff0000','#ffa700','#ffa700','#ffc14b','#ffc14b','#a3cb5c','#a3cb5c','green','green','green']
  


  const [categories,SetCategories] = useState([
                   '12 AM', '1 AM', '2 AM', '3 AM', '4 AM',
                  '5 AM', '6 AM', '7 AM', '8 AM',
                  '9 AM', '10 AM', '11 AM', '12 PM',
                  '1 PM', '2 PM', '3 PM', '4 PM',
                  '5 PM', '6 PM', '7 PM', '8 PM',
                  '9 PM', '10 PM', '11 PM',
              ]);
  const [deviceName,SetDeviceName]=useState('');

const [xAxisLabels,SetXAxisLabel] = useState([])
function GetRandomDigit(min=0,max=10){
  return  Math.floor(Math.random() * (max - min + 1)) + min;
}

  function generateGeateway(count,type){
    let arr=[];
    for(let i=1;i<=count;i++){
      
      arr.push({name:type+" "+i,status:GetRandomDigit(0,1)?"online":'offline',lat:GetRandomDigit(-99,-1),long:GetRandomDigit(1,99),serial:GetRandomDigit(555555,999999),battery:GetRandomDigit(1,100)})
    }
    return arr;
  }

  function handleDateSelect(date) {
    updateSelectedDate(date);

    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

    //setChartDates({ start: startDate, end: endDate });
  }

  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }
function transformData(data,cat) {
  const transformedData = {
    data: {},
    message: "success",
    status: 200,
  };

  data.forEach(({ "Category Name": groupName, "Location  Name": locationName, ...rest }) => {
    if (!transformedData.data[groupName]) {
      transformedData.data[groupName] = [];
    }
    

    transformedData.data[groupName].push({
      group_name: groupName,
      location_name: locationName,
      status: cat[groupName].find(el=>el.location_name.trim() ==locationName.trim() ).status?cat[groupName].find(el=>el.location_name.trim() ==locationName.trim() ).status:" - ", 
      tag_serial: cat[groupName].find(el=>el.location_name.trim() ==locationName.trim() ).tag_serial?cat[groupName].find(el=>el.location_name.trim() ==locationName.trim() ).tag_serial:" - ", 
      ...rest,
    });
  });

  return transformedData;
}

useEffect(() => {
  const fetchData = async () => {
    try {
      const now = new Date();
      const timeZoneOffset = now.getTimezoneOffset();
      const offsetHours = Math.floor(Math.abs(timeZoneOffset) / 60);
      const offsetMinutes = Math.abs(timeZoneOffset) % 60;
      const offsetSign = timeZoneOffset > 0 ? '-' : '+';
      const offsetString = `${offsetSign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;
      const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

    
      SetTimeZoneDetail(`${timeZoneName} ${offsetString}`);

      const requestBody2 = { session: userSession, org_id: org_id };
      const geoLocationResponse = await getLocationCordinate(requestBody2);

      if (geoLocationResponse.status === 200) {
        SetSpaceID(geoLocationResponse.data.space_id);
      }
        const geoLocation = geoLocationResponse && geoLocationResponse.data && geoLocationResponse.data.location?geoLocationResponse.data.location:[];

        const locationStatusResponse = await getLocationStatus(userSession, org_id, sub_org_id);

        if (locationStatusResponse.status === 200) {
          let originalData = locationStatusResponse.data;

          if (parseInt(sub_org_id) === 1 && parseInt(org_id) === 30) {
            originalData = transformData(CMGBulkyWarehouse, locationStatusResponse.data).data;
          } else if (parseInt(sub_org_id) === 2 && parseInt(org_id) === 30) {
            originalData = transformData(CMGManualWarehouse, locationStatusResponse.data).data;
          }

          const transformedData = [];
          const loc = [];

          for (const key in originalData) {
            if (originalData.hasOwnProperty(key) && key) {
              const locationArray = originalData[key].map((entry) => {
                const name = geoLocation.find(el => el.tag_serial === entry.tag_serial);
                return name ? {
                  name: entry.location_name,
                  lat: name.position.x,
                  long: name.position.z,
                  location_tag_serial: entry.tag_serial,
                  status: entry.status
                } : {
                  name: entry.location_name,
                  lat: " - ",
                  long: " - ",
                  location_tag_serial: entry.tag_serial,
                  status: entry.status
                };
              });

              loc.push(...locationArray);

              transformedData.push({
                name: key,
                location: locationArray,
                percentage: parseFloat(((locationArray.filter(item => item.status === "online").length / locationArray.length) * 100).toFixed(2))
              });
            }
          }

          SetLocationList(loc);
          SetLocationAll(transformedData);
          SetLocation(transformedData);
          SetLoaderLcoation(false);
        }

        SetLocationCordinate(geoLocation);
        Setloading(false);
      

      const gatewayStatusResponse = await getGatewayStatus(userSession, org_id, sub_org_id);

      if (gatewayStatusResponse.status === 200) {
        let data = [];
        if (parseInt(sub_org_id) === 1 && parseInt(org_id) === 30) {
          data = CMGBulkyWarehouseGateway.map(gw => gatewayStatusResponse.data.find(el => el.gateway_name.toLowerCase().trim() === gw.GatewayName.toLowerCase().trim())).filter(Boolean);
        } else if (parseInt(sub_org_id) === 2 && parseInt(org_id) === 30) {
          data = CMGManualWarehouseGateway.map(gw => gatewayStatusResponse.data.find(el => el.gateway_name.toLowerCase().trim() === gw.GatewayName.toLowerCase().trim())).filter(Boolean);
        } else {
          data = gatewayStatusResponse.data;
        }

        const arr = data.map(item => ({
          name: item.gateway_name,
          status: item.status,
          lat: " - ",
          long: " - ",
          serial: item.mac_id,
          battery: " - ",
          id: item.id
        }));


        SetGateway(arr);
        SetGatewayAll(arr);
      }
    } catch (err) {
      console.error(err);
    }
  };

  fetchData();
}, [userSession, org_id, sub_org_id]);

  useEffect(() => {
    
    if(elementRef && elementRef.current){
     
      SetHightMap(elementRef.current.getBoundingClientRect().height - 60);
      setWidth(elementRef.current.offsetWidth);
    }

    getTagStatus(userSession,org_id,sub_org_id).then(res=>{
      if(res.status==200){

        SetPersonal(res.data);
        SetPersonalAll(res.data);
      }
    })
    
    
    
  }, [elementRef,spaceID,isloading]);

  useEffect(()=>{
    if (!space) {
      return
    }
    let array2=[]
    let data = [...locationCordinate] 
    if(data.length>0 && locationList.length>0){


      for (let i = 0;i<data.length;i++){
       
      
        let arr={};
        arr.type='add'
        arr.icon={}
        arr.id=data[i].unique_loc_id;
        arr.tooltip=data[i].name 
        arr.position= data[i].position
        if(arr.position && arr.position.elevation){
          arr.position.elevation=GeoElevation
        }
        arr.name = data[i].name
        arr.width = 96

        let status= locationList.find(item=>item.location_tag_serial == data[i].tag_serial)

        if(status && status.status.toLowerCase()=="online"){
          arr.url = ProductiveIcon
        }else{
          arr.url = NonProductiveIcon
        }
 
        
        arr.widthSize=GeoWidth
        //dispatchIcon(arr);
        AddDataLayer(arr)
               array2.push(arr)

      }

    }    
    
  },[locationCordinate,locationList,spaceID,space])

  const octoberDates = [
  '2023-10-01', '2023-10-02', '2023-10-03', '2023-10-04', '2023-10-05',
  '2023-10-06', '2023-10-07', '2023-10-08', '2023-10-09', '2023-10-10',
  '2023-10-11', '2023-10-12', '2023-10-13', '2023-10-14', '2023-10-15',
  '2023-10-16', '2023-10-17', '2023-10-18', '2023-10-19', '2023-10-20',
  '2023-10-21', '2023-10-22', '2023-10-23', '2023-10-24', '2023-10-25',
  '2023-10-26', '2023-10-27', '2023-10-28', '2023-10-29', '2023-10-30',
  '2023-10-31'
];

// const xAxisLabels = octoberDates.map(date => {
//   const parsedDate = new Date(date);
//   return `${parsedDate.getDate()} ${parsedDate.toLocaleString('en-US', { month: 'short' })}`;
// });

const heatmapDataRandomY = [];
for (let y = 0; y <= 23; y++) {
  for (let x = 0; x < 21; x++) { // 31 days in October
    heatmapDataRandomY.push([x, y, Math.round(Math.random())]);
  }
}


useEffect(()=>{
  
},[showModal])
  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }


  useEffect(() => {
    if (props.language) {
      updateSelectedLangValue(props.language);
    }
  }, [props.language]);

  function AddDataLayer(data){

    if(space){
    space.addDataLayer({
      id: data.id,
      type: 'icon',
    
      data: [{
        position: data.position, 
      }],
      icon: {
        url: data.url,
        width: 150,
        height: 150
      },
      width: GeoWidth,
      anchor: 'top',
      tooltip: d => data.tooltip,
      // onClick: d=> filterResultLocation(data.tooltip),
    })
  }
}

  const handleDate = (date, type) => {
   // setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
  };
 
  function changeTab(name){
     // SetonlineTab(name);
    }

function statusRender(params,i){
  var op =document.createElement('div');

  var eGui = document.createElement('div');
    if(params.value=="online"){
      eGui.innerHTML=`<div class="badgeStatus activeBadge">${(params.value)}</div>`;
    }else{
      eGui.innerHTML=`<div class="badgeStatus offlineBadge">${(params.value)}</div>`;
    }

   return eGui;
}

function nameRenderGateway(params){

  var eGui = document.createElement('div');
    
      eGui.innerHTML=`<div class="nameAlign">${(params.value)}<br/><small>MacID:- <strong>${(params.data.serial)}</strong></small></div>`;
    

   return eGui;
}
function positionRenderGateway(params){
      var eGui = document.createElement('div');
    
      eGui.innerHTML=`<div><div class="nameAlign"><strong>Lat</strong>:  ${(params.value)}</div><div class="nameAlign"><strong>Long</strong>: ${(params.data.long)}</div></div>`;
   return eGui;
}

function AGTableGateway(data){

  let  arr=<div className={"AgTableGateway"}
  ><div className="ag-theme-alpine if cell-size-60" style={{height: 30 + ((data.length > 0? data.length:1)*47) ,maxHeight:heightMap  , width: "calc(100% - 1px)"}}>
      
      <AgGridReact
          rowHeight={45}
          autoGroupColumnDef={{
              headerName: 'Name',field: 'name',headerCheckboxSelection: true,
          }}
          headerHeight={45}
          onCellClicked={functionLoadGatewayChart}
       defaultColDef={{sortable: true,resizable: true,flex:1, suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
                     rowData={data}
          key={"AgTableGateway"}
       >
  
      <AgGridColumn
          field="name"
          headerName={"Name"}
          cellRenderer= {(params)=>nameRenderGateway(params)}

      />
      {/* <AgGridColumn field="lat" headerName="Location" cellRenderer={(params) => positionRenderGateway(params)} /> */}
      
      <AgGridColumn maxWidth={100}  field="status" headerName="Status" cellRenderer= {(params)=>statusRender(params)}  />      
      
  
  </AgGridReact>
  </div></div>
  
  return arr
}

function  functionLoadGatewayChart(params){
  
  heatmapChart= null;
  SetShowModal(true);
  SetDeviceName(params.data.name);
  SetLoadAPI(true);

getGatewayHistory(userSession,params.data.serial,moment().subtract(31,'days').format('YYYY-MM-DD 00:00:00'),moment().format('YYYY-MM-DD 23:59:59'),org_id,sub_org_id).then(res=>{

    if(res.data){
const heatmapDt = [];
const data = res.data;
//converting into local array and do conversion.
const heatmapDataArray = [];
for (const date in data) {
  const utcDate = date;

  for (let hour = 0; hour < 24; hour++) {
    const utcHour = hour;
    const localDate = moment.utc(date).set({hour:hour,minute:0,second:0}).local().format('YYYY-MM-DD');
    const localHour = moment.utc(date).set({hour:hour,minute:0,second:0}).local().format('H');

    const isFuture = moment() < moment(localDate).hour(localHour);

    heatmapDataArray.push({
      date: localDate,
      utcDate: utcDate,
      hour: parseInt(localHour),
      utcHour: utcHour,
      value: data[date][hour] ? parseFloat(data[date][hour]) : 0,
      isFuture: isFuture
    });
  }
}

const uniqueDates = [...new Set(heatmapDataArray.map(item => item.date))];
const uniqueHours = [...new Set(heatmapDataArray.map(item => item.hour))];

// Create an empty two-dimensional array for the heatmap
const heatmapData = [];
let index = 0;
const dateAr = [];

for(let i=0;i<uniqueDates.length;i++){
  let dayData= heatmapDataArray.filter(item=> item.date == uniqueDates[i] );
  
  dateAr.push(moment(uniqueDates[i]).format('DD MMM'))

  for(let hour=0;hour<24;hour++){
    //find hour
    let dt= dayData.find(el=>parseInt(el.hour)== hour);

    
    //data is exist
    if(dt){
      if(dt.isFuture){
        heatmapDt.push([index,hour, null])     
      }else{
        heatmapDt.push([index,hour, dt.value])     
      }
    }else{
     const utcHour = hour;
    const localDate = moment.utc(uniqueDates[i]).set({hour:hour,minute:0,second:0}).local().format('YYYY-MM-DD');
    const localHour = moment.utc(uniqueDates[i]).set({hour:hour,minute:0,second:0}).local().format('H');

    const isFuture = moment() < moment(localDate).hour(localHour);
      if(isFuture){
        heatmapDt.push([index,hour, null])
      }else{
        heatmapDt.push([index,hour, 0])
      }

         
    }



  }
  index ++
}


    // Here you can use localDateInUserTimeZone to check if it's greater than the present time
    // if (new Date() < localDateInUserTimeZone) {
    //   heatmapDt.push([index, hour, null]);
    // } else {
    //   heatmapDt.push([
    //     index,
    //     hour,
    //     data[date][hour] ? parseFloat(data[date][hour]) : 0,

    //   ])
    // }

    SetLoadAPI(false);
    SetHeatMapData(heatmapDt);
    
heatmapChart = Highcharts.chart('heatmap-container', {
          chart: {
              type: 'heatmap',
              marginTop: 40,
              marginBottom: 80,
              plotBorderWidth: 1,
              height:600
          },
          exporting:{
            enabled:false
          },
          credits: {
              enabled: false
          },
          title:null,
          xAxis: {
              categories:  dateAr,
              title: null,
              tickPositioner:function () {
                    let positions = []
                    let tick = Math.floor(this.dataMin)
                    let increment = 5                    
                    
        
                    if (this.dataMax === this.dataMin) {
                        return [0]
                    }
                   
                    if (this.dataMax !== null && this.dataMin !== null) {
                        for (tick; tick - increment <= this.dataMax; tick += increment) {
                            positions.push(tick);
                        }
                    }
                 //   positions.splice(positions[positions.length - 1], 1);
                    //positions.push(props.categories.length - 1)
                    
                    return positions;         
              

            },
          },
          yAxis: {
              categories: categories,
              tickPositions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
              title: null,
            reversed: true
          },
          "colorAxis": {
  "min": 0,
  "max": 100,
  "minColor": "#b7b7b7",
  "maxColor": "green",
  "stops": [
    // [null,'#fff'],
    [0, "#b7b7b7"],
    [0.5, "#589a58"],
    // [0.5, "green"],
    [1, "green"]
  ],
  "layout": "vertical",
  "verticalAlign": "top",
  "margin": 10,
  "align": "right"
},
        //   colorAxis: {
        //   min: 0,
        //   max: 100, // Cover a wider range of values
        //   minColor: '#b7b7b7',
        //   maxColor: 'green',
        //     stops: [
        //       [0, '#b7b7b7'],
        //       [1, 'green'],
        //     ],
        //     layout: 'vertical',
        //     verticalAlign: 'top',
        //     margin: 10,
        //     align: 'right'
        // },
          series: [{
              name: 'Device Status',
              borderWidth: 1,
              data:heatmapDt,
              nullColor: '#fff',
              dataClasses: {
                from: 0,
                to: 100,
                breaks: [0, 25, 50, 75, 100]
              },
              dataLabels: {
                  enabled: false,
                  color: 'black',
                  style: {
                      textOutline: 'none',
                  }
              },
              
              tooltip: {
                  headerFormat: '',
                  pointFormatter: function() {

                    const date = dateAr[this.x]
                    const hour = this.y < 12 ? this.y==0?12 + ' AM':this.y + ' AM' : (this.y === 12 ? '12 PM' : (this.y - 12) + ' PM');
                    // const hour = categories[this.y];
                    const value = this.value === 0 ? 'Offline' : 'Online';
                                       
                                        if(this.value === 0){
                                          return date + ' ' + hour + '<br>' + value;
                                        }else{
                                          return date + ' ' + hour + '<br>' + value+": "+(this.value).toFixed(2)+"%";
                                        }
                    
                  }
                }
          }],
           legend: {
    align: 'right',
    verticalAlign: 'middle',
    margin: 10,
    layout: 'vertical'
    
  },
          plotOptions: {
                series: {
                      borderColor: '#ddd' // Set the plot border color to purple
                  }
          },
      });


    
    }
 
  })

}
function  functionLoadLocationChart(params){
 
  heatmapChart= null;
  SetShowModal(true);
  SetDeviceName(params.data.name);
  SetLoadAPI(true);
    let tz=[]
  for(let i=0;i<24;i++){
    tz.push({hour:i,value:moment().set({hour:i,minute:0,second:0}).utc().format('H')})
  }

  getLocationHistory(userSession,params.data.location_tag_serial,moment().subtract(30,'days').format('YYYY-MM-DD 00:00:00'),moment().format('YYYY-MM-DD 23:59:59'),org_id,sub_org_id).then(res=>{

       if(res.data){
const heatmapDt = [];
const data = res.data;
//converting into local array and do conversion.
const heatmapDataArray = [];
for (const date in data) {
  const utcDate = date;

  for (let hour = 0; hour < 24; hour++) {
    const utcHour = hour;
    const localDate = moment.utc(date).set({hour:hour,minute:0,second:0}).local().format('YYYY-MM-DD');
    const localHour = moment.utc(date).set({hour:hour,minute:0,second:0}).local().format('H');

    const isFuture = moment() < moment(localDate).hour(localHour);

    heatmapDataArray.push({
      date: localDate,
      utcDate: utcDate,
      hour: parseInt(localHour),
      utcHour: utcHour,
      value: data[date][hour] ? parseFloat(data[date][hour]) : 0,
      isFuture: isFuture
    });
  }
}


const uniqueDates = [...new Set(heatmapDataArray.map(item => item.date))];
const uniqueHours = [...new Set(heatmapDataArray.map(item => item.hour))];

// Create an empty two-dimensional array for the heatmap
const heatmapData = [];
let index = 0;
const dateAr = [];

for(let i=0;i<uniqueDates.length;i++){
  let dayData= heatmapDataArray.filter(item=> item.date == uniqueDates[i] );
  
  dateAr.push(moment(uniqueDates[i]).format('DD MMM'))

  for(let hour=0;hour<24;hour++){
    //find hour
    let dt= dayData.find(el=>parseInt(el.hour)== hour);
   
    
    //data is exist
    if(dt){
      if(dt.isFuture){
        heatmapDt.push([index,hour, null])     
      }else{
        heatmapDt.push([index,hour, dt.value])     
      }
    }else{
     const utcHour = hour;
    const localDate = moment.utc(uniqueDates[i]).set({hour:hour,minute:0,second:0}).local().format('YYYY-MM-DD');
    const localHour = moment.utc(uniqueDates[i]).set({hour:hour,minute:0,second:0}).local().format('H');

    const isFuture = moment() < moment(localDate).hour(localHour);
      if(isFuture){
        heatmapDt.push([index,hour, null])
      }else{
        heatmapDt.push([index,hour, 0])
      }

         
    }



  }
  index ++
}



    // Here you can use localDateInUserTimeZone to check if it's greater than the present time
    // if (new Date() < localDateInUserTimeZone) {
    //   heatmapDt.push([index, hour, null]);
    // } else {
    //   heatmapDt.push([
    //     index,
    //     hour,
    //     data[date][hour] ? parseFloat(data[date][hour]) : 0,

    //   ])
    // }




    SetLoadAPI(false);
    SetHeatMapData(heatmapDt);
    
heatmapChart = 
Highcharts.chart('heatmap-container', {
          chart: {
              type: 'heatmap',
              marginTop: 40,
              marginBottom: 80,
              plotBorderWidth: 1,
              height:600
          },
          exporting:{
            enabled:false
          },
          credits: {
              enabled: false
          },
          title:null,
          xAxis: {
              categories:  dateAr,
              title: null,
              tickPositioner:function () {
                    let positions = []
                    let tick = Math.floor(this.dataMin)
                    let increment = 5                    
                    
        
                    if (this.dataMax === this.dataMin) {
                        return [0]
                    }
                   
                    if (this.dataMax !== null && this.dataMin !== null) {
                        for (tick; tick - increment <= this.dataMax; tick += increment) {
                            positions.push(tick);
                        }
                    }
                 //   positions.splice(positions[positions.length - 1], 1);
                    //positions.push(props.categories.length - 1)
                    
                    return positions;         
              

            },
          },
          yAxis: {
              categories: categories,
              tickPositions: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
              title: null,
            reversed: true
          },
          "colorAxis": {
  "min": 0,
  "max": 100,
  "minColor": "#b7b7b7",
  "maxColor": "green",
  "stops": [
    // [null,'#fff'],
    [0, "#b7b7b7"],
    [0.5, "#589a58"],
    // [0.5, "green"],
    [1, "green"]
  ],
  "layout": "vertical",
  "verticalAlign": "top",
  "margin": 10,
  "align": "right"
},
        //   colorAxis: {
        //   min: 0,
        //   max: 100, // Cover a wider range of values
        //   minColor: '#b7b7b7',
        //   maxColor: 'green',
        //     stops: [
        //       [0, '#b7b7b7'],
        //       [1, 'green'],
        //     ],
        //     layout: 'vertical',
        //     verticalAlign: 'top',
        //     margin: 10,
        //     align: 'right'
        // },
          series: [{
              name: 'Device Status',
              borderWidth: 1,
              data:heatmapDt,
              nullColor: '#fff',
              dataClasses: {
                from: 0,
                to: 100,
                breaks: [0, 25, 50, 75, 100]
              },
              dataLabels: {
                  enabled: false,
                  color: 'black',
                  style: {
                      textOutline: 'none',
                  }
              },
              
              tooltip: {
                  headerFormat: '',
                  pointFormatter: function() {

                    const date = dateAr[this.x]
                    const hour = this.y < 12 ? this.y==0?12 + ' AM':this.y + ' AM' : (this.y === 12 ? '12 PM' : (this.y - 12) + ' PM');
                    // const hour = categories[this.y];
                    const value = this.value === 0 ? 'Offline' : 'Online';
                                       
                                        if(this.value === 0){
                                          return date + ' ' + hour + '<br>' + value;
                                        }else{
                                          return date + ' ' + hour + '<br>' + value+": "+(this.value).toFixed(2)+"%";
                                        }
                    
                  }
                }
          }],
           legend: {
    align: 'right',
    verticalAlign: 'middle',
    margin: 10,
    layout: 'vertical'
    
  },
          plotOptions: {
                series: {
                      borderColor: '#ddd' // Set the plot border color to purple
                  }
          },
      });


    
    }
 

  })

}

function AGTableLocation(data,key=""){

  let  arr=<div className={"AGTableLocation AgTableGateway"}
  ><div className="ag-theme-alpine if cell-size-40" style={{height: 35 + ((data.length > 0? data.length:1)*35.2) , width: "calc(100% - 1px)"}}>
      
      <AgGridReact
          rowHeight={35}
          autoGroupColumnDef={{
              headerName: 'Name',minWidth: 200,field: 'name',headerCheckboxSelection: true,
          }}
          headerHeight={35}
          // onCellClicked={()=>SetShowModal(true)}
          onCellClicked={functionLoadLocationChart}
       defaultColDef={{sortable: true,resizable: true,minWidth:45, flex:1,suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
                     rowData={data}
          key={"AGTableLocation"+key}
       >
  
      <AgGridColumn
          field="name"
          headerName={"Name"}
          
      />
            <AgGridColumn
          field="location_tag_serial"
          headerName={"Serial"}
        />
      <AgGridColumn field="lat" headerName="Latitude" valueFormatter={(params) =>params.value} />
      <AgGridColumn field="long" headerName="Longitude" valueFormatter={(params) =>params.value} />
          <AgGridColumn field="status" headerName="Status" cellRenderer= {(params)=>statusRender(params)}  />
      
      
  
  </AgGridReact>
  </div></div>
  
  return arr
}
function locationAccord(data){

  
  return (
    <Scrollbars style={{maxHeight:"525px",height:"525px",width:"100%"}}>
    <Collapse accordion className="customAccord customAccordDevice">
      
      {data.map((loc, index) => 
        (
        <Panel header={<div className="costBodayDept" key={loc.name} style={{width:"100%"}}>
          <Row>
            <Col >{loc.name}</Col>
            <Col style={{textAlign:"center"}}>{loc.location.filter(item=>item.status=="online").length}</Col>
            <Col style={{textAlign:"center",color:loc.location.filter(item=>item.status=="offline").length>0?"red":""}}>{loc.location.filter(item=>item.status=="offline").length}</Col>
            <Col style={{textAlign:"center",color:loc.percentage >0?color[Math.round(parseInt(loc.percentage)*0.1)]:"red",fontWeight:"normal",fontSize:"12px" }}>
            
              <div className="highlightDeviceStatus"  style={{backgroundColor:loc.percentage > 0?color[Math.round((loc.percentage*0.1))]:"red",fontSize:"12px"}}></div>
              {loc.percentage > 0?"Online":"Offline"} 
              </Col>
          </Row>
        </div>}>
          <Collapse>
            {AGTableLocation(loc.location)}
                      </Collapse>
        </Panel>
      ))}
    </Collapse>
    </Scrollbars>
  );

}
function AGTablePersonal(data){

  let  arr=<div className={"AgTableGateway AGTablePersonal"}
  ><div className="ag-theme-alpine if cell-size-40" style={{height: 60 + (data.length * 36 >450?450 :data.length*36) , width: "calc(100% - 1px)"}}>
      
      <AgGridReact
          rowHeight={30}
          autoGroupColumnDef={{
              headerName: 'Name',minWidth: 200,field: 'name',headerCheckboxSelection: true,
          }}
          headerHeight={30}
        // onCellClicked={()=>SetShowModal(true)}
       defaultColDef={{sortable: true,resizable: true ,flex:1, suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}
                     rowData={data}
          key={"AGTablePersonal"}
       >
  
            <AgGridColumn
          field="tag_serial"
          headerName={"Tag Serial"}
        />
      <AgGridColumn  maxWidth={100} field="battery" headerName="Battery" valueFormatter={(params) =>params.value+"%"} />
      
      
  
  </AgGridReact>
  </div></div>
  
  return arr
}
function  personalSearchFunction(e){
  SetPersonalSearch(e);
 
  let data= [...personalAll].filter(item=> item.tag_serial.replaceAll(":").toLowerCase().trim().includes(e.replaceAll(":").toLowerCase().trim()) );
    SetPersonal(data);
}
function  gatewaySearchFunction(e){
  SetGatewaySearch(e);

    let data= [...gatewayAll].filter(item=>item.name.toLowerCase().trim().includes(e.toLowerCase().trim()) || item.serial.replaceAll(":").toLowerCase().includes(e.replaceAll(":").toLowerCase().trim()) );

    SetGateway(data);
}

function findLocationName(name) {
  let data = locationAll
  let arr=[]
  
  if(data){
    for(let i=0;i<data.length;i++){
      if(data[i].name.toLowerCase().trim().includes(name.toLowerCase().trim())){
        arr.push(data[i])
      }else{
        let newAr = data[i].location.filter(item=>item.name.toLowerCase().trim().includes(name.toLowerCase().trim()) )
        if(newAr.length> 0){
          let single =data[i];
          single.location= newAr
          arr.push(single);
        }
      }
    }
  return arr;
  }

}

function  locationSearchFunction(e){
  SetLocationSearch(e);

  let name = e;
  let filter = findLocationName(e);
  SetLocation(filter);
}


  return (
             <div className="main-content side-content pt">

<div className="container-fluid">
    <div className="inner-body">
        <Row>
          {/* <Col lg={6}>
            <CommonHeading title="Device Monitor" />
          </Col> */}
        </Row>
        <Row className="m-t" style={{marginBottom:"20px"}}>
          <Col lg={8} ref={elementRef} >
            {isloading? <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:spaceID!=''?
                                         <div  ><Viewer onReady={onReady} spaceID={spaceID} changeTab={changeTab} 
                                          /></div>:<div className='whiteBgInner' style={{minHeight:300,background:"#f8f8f8",padding:30}}><h2>Please Enable Geo Map</h2></div> }
          </Col>
          <Col lg={4}>

            <div className='whiteBgInner' >
              <div className='headerDevice'>
               
                <Row>
                  <Col><h3>Gateway</h3></Col>
                  <Col style={{textAlign:"center"}}><h3><input type="text" className='form-control' value={gatewaySearch} onChange={(e)=>gatewaySearchFunction(e.target.value)} /> </h3></Col>
                </Row>
              </div>
              <div className='contentArea'>
                  {AGTableGateway(gateway)}
              </div>              
            </div>
          </Col>
        </Row>

          <Row className='mt m-t'>
          <Col lg={8} >
            <div className='whiteBgInner' >
              <div className='headerDevice'>
                <Row>
                  <Col><h3>Location Tags</h3></Col>
                  <Col lg={6} style={{textAlign:"center"}}>
                    <h3>
                      <span className='onlineDevice deviceCount' >Online : <strong style={{color:"green"}}>{locationList.filter(item=>item.status=="online").length}</strong></span>
                      <span className='offlineDevice deviceCount'>Offline : <strong  style={{color:"red"}}>{locationList.filter(item=>item.status=="offline").length}</strong></span>
                      <span className='totalDevice deviceCount' >Total : <strong style={{color:color[parseInt((locationList.filter(item=>item.status=="online").length/locationList.length)*100)]}}>{locationList.length}</strong></span>
                  </h3>
                  </Col>
                  <Col style={{textAlign:"center"}}><h3><input type="text" className='form-control' value={locationSearch} onChange={(e)=>locationSearchFunction(e.target.value)}/> </h3></Col>
                </Row>
                
              </div>
              <div className='contentArea' style={{minHeight:"300px"}}>
                {loaderLocation?<div style={{textAlign:"center"}}><img src={spinnerLoader}/></div>:
                  locationAccord(location)}
              </div>              
            </div>
          </Col>
          <Col lg={4}>
                        <div className='whiteBgInner' >
              <div className='headerDevice'>
                <Row>
                  <Col><h3>Personal Tags</h3></Col>
                  <Col style={{textAlign:"center"}}><h3><input type="text" className='form-control' value={personalSearch} onChange={(e)=>personalSearchFunction(e.target.value)}/> </h3></Col>
                </Row>
                
                  
                
              </div>
              <div className='contentArea'>
                  {AGTablePersonal(personal)}
              </div>              
            </div>
   
          </Col>
        </Row>

              <ReactModal
                isOpen={showModal}
                style={customStyles}
                // onRequestClose={()=>SetShowModal(false)}
                
                // shouldCloseOnOverlayClick={true}   
            >
              <h2 style={{marginBottom:"0px",fontSize:18,paddingBottom:15,borderBottom:"1px solid #ddd"}}>{deviceName}  ({timezoneDetail}) 
             <div className='closBtn' style={{cursor:"pointer",width:"30px",height:"30px",background:"#ef5e8c",color:"#fff",
            textAlign: "center",float: "right",lineHeight: "30px",fontSize:12
            }} onClick={()=>{
              if (heatmapChart) {
                    heatmapChart.destroy(); // Destroy the Highcharts chart
                    heatmapChart = null; // Set the chart variable to null
                }
              SetShowModal(false)}}>x</div> 
              </h2>
              
                <div className={"modal-cyclecount"}>
                  {loadAPI?<div style={{marginTop:"150px",textAlign:"center"}}><img src={spinnerLoader}/></div>:<div id='heatmap-container'></div>}
                
                </div>
            </ReactModal>



        <Row className="m-t">
          <Col lg={12}>

          </Col>
        </Row>
    </div>
   </div>
   </div> 
    
  );
}


export default DeviceMonitor
